import { snakeCase } from 'lodash-es';
import { FilterOption, FilterParams } from 'src/types';
import useFilter from 'src/hooks/useFilter';

const Line: React.FC<{
  option: FilterOption;
  inputType: 'checkbox' | 'radio';
  className?: string;
  currentValue?: string | number | string[] | number[] | (number | string | undefined)[];
  inputClassName?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRoot?: boolean;
  prefixFieldName?: string;
  fieldName: keyof FilterParams;
}> = ({
  option,
  inputType,
  className,
  onChange,
  inputClassName,
  isRoot,
  currentValue,
  prefixFieldName,
  fieldName,
}) => {
  const { setFilterParams } = useFilter();
  const id = `${prefixFieldName ? `${prefixFieldName}_` : ''}${snakeCase(option.label)}_${snakeCase(
    String(option.value)
  )}`;
  const name = `${prefixFieldName ? `${prefixFieldName}_` : ''}${fieldName}`;
  const isChecked = currentValue
    ? typeof currentValue !== 'object'
      ? String(currentValue) === String(option.value)
      : 'includes' in currentValue
      ? (currentValue.map(v => String(v)) as (string | number)[]).includes(String(option.value))
      : false
    : false;
  return (
    <label className={`tw-flex tw-cursor-pointer tw-items-start ${className || ''}`}>
      <div className="form-check tw-mr-2">
        <input
          type={inputType}
          name={name}
          data-root={isRoot ? 1 : undefined}
          className={`form-check-input ${inputClassName || ''}`}
          id={id}
          value={option.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFilterParams((prev) => {
              const aryValue =
                inputType === 'checkbox' ? ((prev[fieldName] || []) as (string | number)[]) : [];
              const filteredValue = aryValue.filter((v) => String(v) !== String(option.value));
              const newValue =
                inputType === 'radio'
                  ? e.target.checked
                    ? String(option.value)
                    : undefined
                  : inputType === 'checkbox'
                  ? e.target.checked
                    ? aryValue.concat([String(option.value)])
                    : filteredValue.length > 0
                    ? filteredValue
                    : undefined
                  : undefined;
              return {
                ...prev,
                [fieldName]: newValue,
              };
            });
            typeof onChange === 'function' && onChange(e);
          }}
          checked={isChecked}
        />
        <label className="form-check-label" htmlFor={id}></label>
      </div>
      <div className="">
        <div className="tw-text-brand-navy tw-text-sm">{option.label}</div>
        {option.desc ? <div className="tw-text-grey-light tw-text-sm">{option.desc}</div> : null}
      </div>
    </label>
  );
};

export default Line;
