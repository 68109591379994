import { isArray, isPlainObject } from 'lodash-es';
import { FormikErrors } from 'formik';

export const setTouchedErrorFields = <T>(errors: FormikErrors<T>) => {
  return Object.keys(errors).reduce((memo, key) => { // @ts-ignore
    memo[key] = isArray(errors[key]) // @ts-ignore
      ? errors[key].map((obj) => setTouchedErrorFields(obj))// @ts-ignore
      : isPlainObject(errors[key])// @ts-ignore
      ? setTouchedErrorFields(errors[key])
      : true
    return memo
  }, {})
}
