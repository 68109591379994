import { TMoney } from 'src/types';
import currencies from 'src/data/currencies';
import { round } from 'lodash-es';

export const amountToCents = (amount: TMoney) => {
  const currency = currencies.find(o => o.code === amount.currency)
  if (!currency) {
    throw Error(`Encounter unsupported currency ${amount.currency}`)
  }

  return round(Number(amount.amount) * (currency.exponent === 2 ? 100 : 1), 0)
};
