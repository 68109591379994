import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { ConfirmDialogContext } from 'src/contexts/ConfirmDialogProvider';
import { FiX } from 'react-icons/fi';

export default function ConfirmDialog() {
  const { show, setShow, data } = useContext(ConfirmDialogContext);

  const handleOk = () => {
    setShow(false);
    data.resolve();
  };

  const handleCancel = () => {
    setShow(false);
    data.reject();
  };

  return (
    <Modal
      show={show}
      onHide={handleCancel}
      className="modal-zindex-1051"
      dialogClassName="modal-dialog-centered"
      backdropClassName="modal-zindex-1051"
    >
      <Modal.Header>
        <div className="tw-text-xl tw-font-semibold">{data.title}</div>
        <button type="button" className="close" onClick={handleCancel}>
          <FiX size={24} />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div>{data.body}</div>

        <div className="tw-text-right tw-mt-8">
          <button type="button" className="btn btn-secondary tw-mr-2" onClick={handleCancel}>
            {data.cancelText}
          </button>
          <button type="button" className="btn btn-primary" onClick={handleOk}>
            {data.okText}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
