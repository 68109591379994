import React, { useEffect, useState } from 'react';
import useQuery from 'src/hooks/useQuery';
import { ApiRoutes } from 'src/lib/routes';
import { Cart } from 'src/types';
import { ZERO_AMOUNT } from 'src/lib/constants';
import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import { KeyedMutator } from 'swr';
import { Camelized } from 'humps';
import useCurrentUser from 'src/hooks/useCurrentUser';

const DEFAULT_CART: Cart = {
  id: 0,
  orderItemId: null,
  numPossibleRedemptions: 0,
  useCredit: false,
  deductedCreditAmount: ZERO_AMOUNT,
  totalAmount: ZERO_AMOUNT,
  exchangedTotalAmountWithConversionFee: ZERO_AMOUNT,
  exchangedDeductedCreditAmount: ZERO_AMOUNT,
  exchangedSubtotalAmount: ZERO_AMOUNT,
  exchangedSubtotalAmountWithConversionFee: ZERO_AMOUNT,
  exchangedReferralAmount: ZERO_AMOUNT,
  exchangedPromoCodeDiscountAmount: ZERO_AMOUNT,
  totalMasterCrossOutPrice: ZERO_AMOUNT,
  totalCrossOutPrice: ZERO_AMOUNT,
  universalPromoCodeDiscountAmount: ZERO_AMOUNT,
  universalExchangedPromoCodeDiscountAmount: ZERO_AMOUNT,
  subtotalAmount: ZERO_AMOUNT,
  promotionCode: null,
  promoCodeDiscountAmount: ZERO_AMOUNT,
  orderItem: null,
  referralAmount: ZERO_AMOUNT,
  giftCode: null,
  giftCodeAmount: ZERO_AMOUNT,
  exchangedGiftCodeAmount: ZERO_AMOUNT,
  fullPaymentByCredit: false
};

export const CartContext = React.createContext<
  [Cart, (cart: Cart | null) => void, KeyedMutator<Camelized<Cart>>]
>([DEFAULT_CART, () => {}, () => { return new Promise<Camelized<Cart>>(()=>{})}]);

const CartProvider: React.FC = ({ children }) => {
  const [currentUser] = useCurrentUser();
  const [cartId, setCartId] = useState<number>();
  const currentCurrency = useCurrentCurrency()
  const { data: cart = DEFAULT_CART, mutate } = useQuery<Cart>(
    typeof cartId === 'number'
      ? ApiRoutes.apiCartRoute(
          {},
          { cart_id: cartId, view: 'with_order_item', currency: currentCurrency }
        ).toUrl()
      : null,
    {
      revalidateOnFocus: true,
      dedupingInterval: 1000,
      focusThrottleInterval: 1000,
      fetcherOpts: {
        corsEnable: true,
      },
    }
  );

  const setCurrentCart = (cart: Cart | null) => {
    if (cart) {
      const validCartId = typeof cart.id === 'number' && cart.id > 0
      if (validCartId && Number(window.localStorage.getItem('cart_id')) !== cart.id) {
        setCartId(cart.id);
        window.localStorage.setItem('cart_id', String(cart.id));
      } else {
        if (validCartId) {
          mutate(cart);
        } else {
          mutate();
        }
      }
    } else {
      setCartId(undefined)
      window.localStorage.removeItem('cart_id');
    }
  };

  useEffect(() => {
    if (typeof window === 'object') {
      const id = window.localStorage.getItem('cart_id');
      if (id) {
        setCartId(Number(id));
      } else if (currentUser?.cartId) {
        setCartId(currentUser?.cartId);
      }
    }
  }, [currentUser?.cartId]);

  return <CartContext.Provider value={[cart, setCurrentCart, mutate]}>{children}</CartContext.Provider>;
};

export default CartProvider;
