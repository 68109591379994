export const TOKEN_KEY = 'ss_access_token'

export function setAccessToken(token: string) {
  if (typeof window === 'object') {
    window.localStorage.setItem(TOKEN_KEY, token)
  }
}

export function getAccessToken() {
  if (typeof window === 'object') {
    return window.localStorage.getItem(TOKEN_KEY)
  }
}

export function removeAccessToken() {
  if (typeof window === 'object') {
    window.localStorage.removeItem(TOKEN_KEY)
  }
}
