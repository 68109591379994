import { Md20Mp } from 'react-icons/md';
import { ICurrency } from 'src/types';

const currencies: ICurrency[] = [
  {
    code: 'USD',
    fullName: 'US Dollar',
    decimalMark: '.',
    exponent: 2,
    prefix: '$',
    suffix: ' USD',
    symbol: '$',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      50,
      100,
      200,
      500
    ]
  },
  {
    code: 'EUR',
    fullName: 'Euros',
    decimalMark: ',',
    exponent: 2,
    prefix: '€',
    suffix: null,
    symbol: '€',
    thousandsSeparator: '.',
    commonGiftAmounts: [
      50,
      100,
      200,
      500
    ]
  },
  {
    code: 'SGD',
    fullName: 'Singaporean Dollar',
    decimalMark: '.',
    exponent: 2,
    prefix: '$',
    suffix: ' SGD',
    symbol: 'S$',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      50,
      100,
      200,
      500
    ]
  },
  {
    code: 'GBP',
    fullName: 'British Pounds',
    decimalMark: '.',
    exponent: 2,
    prefix: '£',
    suffix: null,
    symbol: '£',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      50,
      100,
      200,
      500
    ]
  },
  {
    code: 'AUD',
    fullName: 'Australian Dollar',
    decimalMark: '.',
    exponent: 2,
    prefix: '$',
    suffix: ' AUD',
    symbol: 'A$',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      50,
      100,
      200,
      500
    ]
  },
  {
    code: 'MYR',
    fullName: 'Malaysian Ringgit',
    decimalMark: '.',
    exponent: 2,
    prefix: 'RM',
    suffix: null,
    symbol: 'RM',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      200,
      400,
      800,
      1000
    ]
  },
  {
    code: 'VND',
    fullName: 'Vietnamese Dong',
    decimalMark: ',',
    exponent: 0,
    prefix: null,
    suffix: 'đ',
    symbol: 'đ',
    thousandsSeparator: '.',
    commonGiftAmounts: [
      500000,
      1000000,
      2000000,
      5000000
    ]
  },
  {
    code: 'IDR',
    fullName: 'Indonesian Rupiah',
    decimalMark: ',',
    exponent: 2,
    prefix: 'Rp',
    suffix: null,
    symbol: 'Rp',
    thousandsSeparator: '.',
    commonGiftAmounts: [
      50000,
      100000,
      200000,
      500000,
      1000000,
      2000000,
      5000000
    ]
  },
  {
    code: 'LKR',
    fullName: 'Sri Lanka Rupee',
    decimalMark: '.',
    exponent: 2,
    prefix: 'Rs',
    suffix: null,
    symbol: 'Rs',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      1000,
      2000,
      5000,
      10000,
      20000,
      50000,
      100000
    ]
  },
  {
    code: 'INR',
    fullName: 'Indian Rupee',
    decimalMark: '.',
    exponent: 2,
    prefix: '₹',
    suffix: null,
    symbol: '₹',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      100,
      200,
      500,
      1000,
      2000,
      5000,
      10000
    ]
  },
  {
    code: 'NPR',
    fullName: 'Nepalese Rupee',
    decimalMark: '.',
    exponent: 2,
    prefix: 'रु',
    suffix: null,
    symbol: 'रु',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      500,
      1000,
      2000,
      5000,
      10000,
      20000
    ]
  },
  {
    code: 'TWD',
    fullName: 'Taiwan Dollar',
    decimalMark: '.',
    exponent: 2,
    prefix: 'NT$',
    suffix: null,
    symbol: 'NT$',
    thousandsSeparator: ',',
    commonGiftAmounts: [
      100,
      200,
      500,
      1000,
      2000,
      5000,
      10000
    ]
  },
  {
    code: 'THB',
    fullName: 'Thai Baht',
    decimalMark: '.',
    exponent: 2,
    prefix: '฿',
    symbol: '฿',
    suffix: null,
    thousandsSeparator: ',',
    commonGiftAmounts: []
  },
  {
    code: 'CNY',
    fullName: 'Chinese Renminbi Yuan',
    decimalMark: '.',
    exponent: 2,
    prefix: '¥',
    symbol: '¥',
    suffix: null,
    thousandsSeparator: ',',
    commonGiftAmounts: []
  },
  {
    code: 'JPY',
    fullName: 'Japanese Yen',
    decimalMark: '.',
    exponent: 0,
    prefix: null,
    symbol: '円',
    suffix: '円',
    thousandsSeparator: ',',
    commonGiftAmounts: []
  },
  {
    code: 'NZD',
    fullName: 'New Zealand Dollar',
    decimalMark: '.',
    exponent: 2,
    prefix: '$',
    symbol: 'NZ$',
    suffix: ' NZD',
    thousandsSeparator: ',',
    commonGiftAmounts: []
  },
  {
    code: 'CAD',
    fullName: 'Canadian Dollar',
    decimalMark: '.',
    exponent: 2,
    prefix: '$',
    symbol: 'C$',
    suffix: ' CAD',
    thousandsSeparator: ',',
    commonGiftAmounts: []
  },
  {
    code: 'CHF',
    fullName: 'Swiss Franc',
    decimalMark: '.',
    exponent: 2,
    prefix: 'CHF',
    symbol: 'CHF',
    suffix: null,
    thousandsSeparator: ',',
    commonGiftAmounts: []
  }
];

export default currencies;
